import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dialog, Slide, Grid, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { S3_ASSETS_PATH } from '../../constants';
import { withStyles } from '@material-ui/core/styles';
import parse from 'html-react-parser';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    background: '#0091B3',
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    borderRadius: '10px 10px 0px 10px',
    maxWidth: 200,
    fontFamily: 'Roboto',
    marginTop: 40,
    fontWeight: 400,
  },
}))(Tooltip);

const billingInfo = [
  { type: 'Campaign creation', desc: 'May 5' },
  {
    type: `First invoice <span>(covering three months of charges, May 5-Aug 5), $30</span>`,
    desc: 'May 31 (end of calendar month of campaign creation)',
  },
  { type: `First payment due`, desc: 'June 30 (net 30 days)' },
  {
    type: 'First Campaign renewal <span>(anniversary) date</span>',
    desc: 'August 5 (three months after campaign creation)',
  },
  {
    type: 'Cancellation notice due <span>(to avoid renewal billing)</span>',
    desc: 'July 20 (15 days prior to renewal date)',
  },
  {
    type: 'Second invoice  <span>(three months charges, Aug 5-Nov 5)</span>',
    desc: 'August 31 (end of calendar month of renewal date)',
  },
  { type: 'Second payment due', desc: 'September 30 (net 30 days) ' },
];

const feesInfo = [
  {
    category: 'One Time Setup Fees',
    type: 'CSP Registration',
    fee: '$200.00',
    desc: 'One time, non-refundable application fee, which includes validation with MNOs and DCAs, and/or vetting fees as required, as well as onboarding support. There is no fee for maintenance or renewal of CSP accounts.',
  },
  {
    category: '',
    type: 'Brand Registration',
    fee: '$4',
    desc: 'One-time fee, which includes corrections and re-submissions of brand information, as part of TCR brand registration process. This charge excludes any 3rd party vetting fees or non-standard review procedures as mandated by MNOs.',
  },
  {
    category: 'Monthly Campaign Fees',
    type: 'Regular Campaigns',
    fee: '$10.00 / month',
    desc: 'Billed in advance, for a 3 month period.',
  },
  {
    category: '',
    type: 'Low Volume Mixed Campaign',
    fee: '$2.00 / month',
    desc: 'Billed in advance, for a 3 month period.',
  },
  {
    category: '',
    type: 'Special Campaigns - Other categories (Political, Charity, etc.)',
    fee: 'TBD',
    desc: 'Standard fees by campaign type to be determined once MNO screening requirements are agreed, and costs are determined.',
  },
  {
    category: '3rd Party Vetting Fees',
    type: 'Aegis Standard Brand Vetting',
    fee: '$40 per vet',
    desc: 'One-time, non-refundable vetting fee.',
  },
];

const serviceInfo = [
  {
    level: 'Severity 1',
    desc: 'Complete outage of service preventing client’s ability to communicate with platform and process campaigns',
    response:
      '<li>Notification to client within 1hr </li> <li>Resolution or work around by next business day, else work will continue until fully resolved.</li><li>Daily client updates</li>',
  },
  {
    level: 'Severity 2',
    desc: 'Service degradation with accessible work around.',
    response:
      '<li>Notification to client within 2hrs of incident identification </li> <li>Resolution or work around in 2 business days, else work will continue until fully resolved.</li><li>Daily client updates</li>',
  },
  {
    level: 'Severity 3',
    desc: 'Minor performance degradation with accessible work around.',
    response:
      '<li>Notification to client upon incident identification.</li> <li>Work will be continuous until the issue is fully resolved.</li><li>Clients will be notified as updates become available.</li>',
  },
  {
    level: 'Severity 4',
    desc: 'Information request, portal access, billing record request, miscellaneous.',
    response:
      '<li>Notification to client upon incident identification.</li> <li>Work will be continuous until the issue is fully resolved.</li><li>Clients will be notified as updates become available.</li>',
  },
];

const escalationInfo = [
  {
    level: '1st',
    escalation:
      'support@campaignregistry.com Or Customer Ticket Portal https://campaignregistry.freshdesk.com/support/home ',
  },
  { level: '2nd', escalation: 'Account Manager' },
  { level: '3rd', escalation: 'Head of TCR Operations VP of Technology ' },
];

const TermsAndConditions = (props) => {
  const [open, setOpen] = useState(false);
  const [enable, setEnabled] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [termsConditions, setTermsConditions] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleScroll = (e) => {
    const element = e.target;
    if (
      Math.floor(element.scrollHeight - element.scrollTop) <=
      element.clientHeight
    ) {
      // do something at end of scroll
      setEnabled(true);
    }
  };
  useEffect(() => {
    setTermsConditions(props.termsConditions[0]);
  }, [props.termsConditions]);
  return (
    <div className="terms-conditions-wrapper">
      <p className="tc-button text-center">
        <a onClick={handleClickOpen}>
          Click <span>here to view and agree to T&Cs.</span>
        </a>
      </p>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className="temrs-conditions-dialog"
      >
        <Grid container className="terms-conditions-container">
          <Grid item xs={12}>
            <h3 className="heading2 text-center">
              <img src={`${S3_ASSETS_PATH}/images/terms-conditions-icon.svg`} />
              <span>
                {termsConditions?.title
                  ? `${termsConditions.title.toUpperCase()}`
                  : 'LEGAL TERMS AND CONDITIONS'}
              </span>
              <CloseIcon onClick={handleClose} className="close-icon" />
            </h3>
          </Grid>
          <Grid
            container
            className="content-blk custom-scrollbar"
            style={{ maxHeight: window.innerHeight - 180 }}
            onScroll={handleScroll}
          >
            <div className="content">
              {!termsConditions ||
              !termsConditions.content ||
              termsConditions.content.length < 1
                ? null
                : termsConditions.content.map((content, index) => {
                    const topic = content.topic.toLowerCase();
                    if (topic.indexOf('exhibit') === -1) {
                      return (
                        <React.Fragment key={index}>
                          {parse(content.text)}
                        </React.Fragment>
                      );
                    } else {
                      const classes = topic.replace('exhibit', 'exhibit-blk');
                      return (
                        <div className={classes} key={index}>
                          {parse(content.text)}
                        </div>
                      );
                    }
                  })}
            </div>
          </Grid>
          <Grid item xs={12} className="footer-blk">
            <ul className="list-inline">
              <li>
                <p>
                  <a onClick={handleClose} className="secondary-btn">
                    Cancel
                  </a>
                </p>
              </li>
              <li>
                <LightTooltip
                  title={
                    enable
                      ? ''
                      : 'Please scroll down to read and agree the terms&conditions'
                  }
                  placement="top-end"
                >
                  <p>
                    <a
                      className={
                        enable ? 'primary-btn' : 'primary-btn disabled'
                      }
                      onMouseEnter={() => setOpenTooltip(true)}
                      onMouseLeave={() => setOpenTooltip(false)}
                      onClick={() => {
                        handleClose();
                        props.handleClick();
                      }}
                    >
                      I Agree
                    </a>
                  </p>
                </LightTooltip>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};
const mapStateToProps = (state) => ({
  termsConditions: state.shareReducer.termsConditions,
});
export default connect(mapStateToProps)(TermsAndConditions);
